<template>
  <div
      class="cursor-pointer"
      :class="{'rad': sendValue === true}"
      :style="{'height': height, 'width': width, 'border': border, 'border-radius': radius, 'background-color': background}"
      @click="position =! position"
  >
    <div
        v-if="sendValue"
        class="bord w-full h-full"
    />
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
  },

  props: {
    deseable: {
      type: Boolean,
      default: false
    },
    all: {
      type: Boolean,
      default: false
    },
    valueT: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '16px'
    },
    width: {
      type: String,
      default: '16px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      position: false
    }
  },

  created () {
    this.position = this.valueT
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      let retour = this.position
      if (this.all === true){
        retour = true
      }
      this.$emit('info', this.position)
      return retour
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

.rad{
  padding: 2px;
  border: 1px solid $oho-blue-clear!important;
}
.bord{
  border: 0.5px solid yellow;
  background-color: $oho-blue-clear;
  border-radius: 2px;
}
</style>

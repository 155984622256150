<template>
  <div class="p-10 pr-16">
    <popupCharge :activation="charge" v-if="charge" />
    <startCom :activation="activeStart" :donnes="devis" v-if="activeStart" @oga="retourStart"/>
    <paye-alert :activation="activeAlert" v-if="activeAlert" @oga="retourAlert"/>
    <div>
      <div class="flex items-center">
        <div class="w-3/6 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            Détail de commande
          </div>
        </div>

      </div>

      <div class="mt-6">

        <div class="w-full">
          <commande :donnes="devis" v-if="devis !== null" @actualise="getDevis"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import add from '../../assets/icons/add.svg'
import commande from '../../component/cdevs/detailsMontage/commande'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import startCom from '../../component/popup/startCommande'
import payeAlert from '../../component/popup/alertPaye'


export default {
  name: "Index",

  components: {
    commande,
    popupCharge,
    startCom,
    payeAlert
  },

  data () {
    return {
      icons: {
        back,
        add
      },
      charge: true,
      devis: null,
      activeStart: false,
      activeAlert: false
    }
  },

  created () {
    if (this.$route.query.x !== undefined) {
      this.getDevis()
    }
  },

  methods: {
    getDevis () {
      this.devis = null
      this.charge = true
      http.get(apiroutes.baseURL + apiroutes.getCommande+ '?id=' + this.$route.query.x)
          .then(response => {
            console.log(response)
            this.devis = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourBack () {
      this.$router.push('/montage')
    },

    retourStart (answer) {
      if (answer) {
        this.activeAlert = true
      }
      this.activeStart = false
    },

    retourAlert (answer) {
      this.activeAlert = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
</style>

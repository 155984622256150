<template>
  <section class="success">
    <popup-base
        v-show="wantToMakeOffer"
        class="popo md:pt-10 md:pb-4 w-full"
        @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
            class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
                class="text-c24 font-c7 w-4/5"
            >
              MONTAGE
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                  :data="icons.fermer"
                  height="30"
                  width="30"
                  class="cursor-pointer"
                  original
                  @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

<!--            <div class="flex items-center text-c18 mt-5">-->
<!--              <radio :value-t="verif" @info="retourVerif"/>-->
<!--              <div class="ml-5">Vérification des verres</div>-->
<!--            </div>-->

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="meullage" @info="retourMeulage"/>
              <div class="ml-5">Meulage</div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="finition" @info="retourFinition" />
              <div class="ml-5">Finition</div>
            </div>


            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="reinage" @info="retourReinage"/>
              <div class="ml-5">Rainage</div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="percage" @info="retourPerc"/>
              <div class="ml-5">Perçage</div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="habillage" :error="state" @info="retourRhab"/>
              <div class="ml-5">Rhabillage</div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="netoyage" @info="retourNettoyage"/>
              <div class="ml-5">Nettoyage</div>
            </div>

            <div class="flex items-center text-c18 mt-5">
              <radio :value-t="livraison" @info="retourLiv"/>
              <div class="ml-5">Livraison</div>
            </div>

            <div
                v-if="error !== null"
                class="mt-6 text-red text-center text-c13"
            >
              {{ error }}
            </div>
            <div class="diviser mt-4"/>

            <div class="w-full flex mt-4">
              <buton
                  class="w-1/2 ml-4"
                  label="ENREGISTRER"
                  radius="5px"
                  size="16px"
                  height="45px"
                  background="#EAEAEF"
                  color="#606060"
                  :charge="charge"
                  @oga="$emit('oga', true)"
              />
              <buton
                  class="w-1/2 ml-4"
                  label="VALIDER L'ÉTAPE"
                  size="16px"
                  radius="5px"
                  height="45px"
                  background="#2B9E7C"
                  :charge="charge"
                  @oga="valider"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import radio from '../helper/form/radio'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    radio
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      error: null,
      charge: false,
      state: null,
      verif: false,
      netoyage: false,
      livraison: false,
      habillage: false,
      reinage: false,
      percage: false,
      finition: false,
      meullage: false,

    }
  },

  created() {
    this.wantToMakeOffer = this.activation

   // Chargement automatique des données
    if (this.info.orderAssemblyStep) {
      let data = this.info.orderAssemblyStep
      for (let item in data) {
        if (data[item].state === 'DELIVERY') {
          this.livraison = true
        }
        if (data[item].state === 'CLEANING') {
          this.netoyage = true
        }
        if (data[item].state === 'DRESSING') {
          this.habillage = true
        }
        if (data[item].state === 'DRILLING') {
          this.percage = true
        }
        if (data[item].state === 'CREASING') {
          this.reinage = true
        }
        if (data[item].state === 'FINISHING') {
          this.finition = true
        }
        if (data[item].state === 'GRINDING') {
          this.meullage = true
        }
        if (data[item].state === 'VERIFICATION') {
          this.verif = true
        }
      }
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    valider () {
      this.charge = true
      let body = {
        id: this.info.id,
        mounted: true
      }

      http.post(apiroutes.baseURL + apiroutes.validateAssembly, body)
          .then(response => {
            console.log(response)
            this.charge = false
            this.$emit('oga', true)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    // Components responses session
    retourLiv (answer) {
      if (answer) {
        this.validation('DELIVERY')
      }
    },

    retourNettoyage (answer) {
      if (answer) {
        this.validation('CLEANING')
      }
    },

    retourRhab (answer) {
      if (answer) {
        this.validation('DRESSING')
      }
    },

    retourPerc (answer) {
      if (answer) {
        this.validation('DRILLING')
      }
    },

    retourReinage (answer) {
      if (answer) {
        this.validation('CREASING')
      }
    },

    retourFinition (answer) {
      if (answer) {
        this.validation('FINISHING')
      }
    },

    retourMeulage (answer) {
      if (answer) {
        this.validation('GRINDING')
      }
    },

    retourVerif (answer) {
      if (answer) {
        this.validation('VERIFICATION')
      }
    },


    validation (status) {
      this.charge = true
        let body = {
          id: this.info.id,
          state: status,
          meta: {}
        }

        http.post(apiroutes.baseURL + apiroutes.validateAssembly, body)
            .then(response => {
              console.log(response)
              this.charge = false
                // this.$router.push('/montage')
            })
            .catch(error => {
              this.charge = false
              console.log(error)
            })
    },


    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    }
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/styles/sass/mixin";
.success {
  z-index: 999;
}
.cardinal {
  width: 42%;
}

.diviser{
  height: 1px;
  background-color: #dddddd;
}
.erro {
  color: red;
  text-decoration:line-through
}
.success-container {
  font-family: $font-default;
  width: 100%;
  background-color: #F5F5F5;
  height: auto;
  margin: auto;
  border-radius: 12px;
}
@media screen and (max-width: 700px){
  .success-container {
    width: 100%;
    height: 100vh;
  }
  .titre {
    font-size: 18px;
  }
  .bloc1 {
    width: 100%;
  }
  .bloc2 {
    width: 100%;
    font-size: 18px;
  }
  .button {
    width: 100%;
    font-size: 16px;
  }
  .cardinal {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .cardinal{
    width: 65%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .cardinal{
    width: 90%;
  }
  .titre{
    font-size: 18px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 19px;
  }
}
@media screen and (max-width: 320px){
  .cardinal{
    width: 100%;
  }
  .titre{
    font-size: 14px;
  }
  .button{
    width: 100%;
    height: 55px;
    font-size: 15px;
  }
}
</style>
